$primary: #1fc36a;
$primary1: #1fc36a;
$primary2: #216869;
$primary3: #5c4ae4;
$gray1: #444444;
$gray2: #666666;
$gray3: #888888;
$gray4: #cccccc;
$gray5: #eeeeee;
$gray6: #efefef;

$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;


:deep(.duplicates) {
	text-align: center;
	.row {
		max-width: 650px;
		margin: 0 auto;
	}
	h1 {
		display: inline-block;
		width: 100%;
		font-size: 2em;
		font-weight: 700;
		text-align: center;
	}
	p {
		text-align: center;
		display: inline-block;
		width: 100%;
	}
	.duplicate-list {
		display: inline-block;
		width: 100%;

		h2 {
			font-size: 1.25em;
			margin: 0;
			padding: 0;
			font-weight: 700;
			color: black;
			input {
				margin-right: 15px;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		li {

			position: relative;
			text-align: left;
			margin-bottom: 15px;
			input {
				position: absolute;
				left: 30px;
				top: 30%;
				width: 30px;
				height: 30px;
				visibility: hidden;
			}
			input:checked + span.dup-wrap {
				border: 2px solid $primary1;
				display: inline-block;
				width: 100%;
			}
			.dup-wrap {
				cursor: pointer;
				display: inline-block;
				width: 100%;
				padding: 20px;
				border: 2px solid $gray5;
				box-shadow: 0 6px 14px rgba(233, 236, 240, 0.6);
				border-radius: 8px;
			}
			label {
				display: inline-block;
				width: 100%;
			}
		}

		.detail {
			font-size: 0.85em;
		}
	}
	.none {
		display: inline-block;
		width: 100%;
		border: 2px solid $gray5;
		box-shadow: 0 6px 14px rgba(233, 236, 240, 0.6);
		border-radius: 8px;
		text-align: left;

		h2 {
			font-weight: 700;
			font-size: 1.25em;
			margin: 0;
			padding: 0;
			color: black;
			input {
				margin-right: 15px;
			}
		}
		label {
			cursor: pointer;
			display: inline-block;
			width: 100%;
			padding: 30px;
		}
	}
	.save {
		button {
			font-weight: 600;
			text-transform: uppercase;
			margin: 10px 0;
			width: 100%;
			display: inline-block;
			padding: 10px;
		}
	}
}
